import { setAuthData } from "../api/Auth";
import { popupCenter } from "../helpers/popupCenter";
import jwtDecode from "jwt-decode";
import {
  logCustomEvent,
  loginEvent,
  registerSuccessEvent,
} from "../api/Analytics/enhancedEcommerce";
import { useCallback, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { sendWebViewEvent } from "../helpers/webview";
import { useSelector } from "react-redux";

const useOAuth = (nextPath = "/", authType = "login") => {
  const mobileAppVersion = useSelector(({ common }) => common.mobileAppVersion);
  const popup = useRef(null);

  useEffect(() => {
    const handleMessage = event => {
      if (event.origin !== process.env.SITE_URL) {
        console.warn("Untrusted origin:", event.origin);
        return;
      }

      if (event.data?.startsWith?.("login-success")) {
        const callbackUrl = event.data.split("||")[1];
        loginFromCallbackURL(callbackUrl);
        window.location.replace(nextPath);

        if (popup.current) popup.current.close();
      } else if (event.data === "login-cancelled") {
        // setIsLoggedIn(false);
        if (popup.current) popup.current.close();
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const openOAuthPopup = (driver, type = "login") => {
    const getSSOURL = () => {
      if (driver === "phone") {
        const cookie = new Cookies();
        const ref_code = cookie.get("ref_code") || "";
        const utm_source = sessionStorage.getItem("utm_source") || "";
        return `${process.env.OAUTH_URI}/${type}?next=${encodeURIComponent(
          process.env.WEB_LOGIN_CALLBACK
        )}&ref_code=${ref_code}&utm_source=${utm_source}`;
      }
      return `${
        process.env.API_HOSTNAME
      }/v1/user/oauth/connect?driver=${driver}&redirect=true&redirect_uri=${encodeURIComponent(
        `${process.env.OAUTH_URI}/oauth/callback?driver=${driver}&app=buyer_webmobile`
      )}`;
    };
    const ssoURL = getSSOURL();

    popup.current = popupCenter({
      url: ssoURL,
      h: 500,
      w: 500,
      title: `Đăng nhập với ${driver}`,
    });
  };

  const loginFromCallbackURL = useCallback(async callbackURL => {
    // handle login
    const params = parseDeeplinkCallback(callbackURL);
    const { access_token, refresh_token, oauth_key } = params;
    if (access_token && refresh_token) {
      try {
        let userID = jwtDecode(access_token).sub;
        setAuthData({
          authToken: access_token,
          refreshToken: refresh_token,
          userId: userID,
        });

        sendWebViewEvent("AuthChange", {
          accessToken: access_token,
          refreshToken: refresh_token,
          nextPath: nextPath,
        });

        if (authType === "register") {
          // facebook pixel event
          if (typeof window.fbq === "function") {
            window.fbq(
              "track",
              "CompleteRegistration",
              {},
              { eventID: `user:${userID}` }
            );
          }
          // google analytic event
          registerSuccessEvent();
          logCustomEvent(mobileAppVersion, {
            event: "sign_up",
          });
        } else {
          // enhanced ecommerce
          loginEvent("tts");
        }
      } catch (error) {
        // There was an error on the native side
      }
    }
  }, []);

  return { openOAuthPopup };
};

const parseDeeplinkCallback = url => {
  let regex = /[?&]([^=#]+)=([^&#]*)/g,
    params = {},
    match;
  while ((match = regex.exec(url))) {
    params[match[1]] = match[2];
  }
  return params;
};

export default useOAuth;
