import React, { useEffect, useState, useCallback } from "react";
import { useRouter } from "next/router";

import AppLayout from "#Root/layouts/Layout";
import { Login } from "#Root/api/Auth";

import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";
import Button from "@material-ui/core/Button";
import { REGISTER_SUCCESS_CALLBACK } from "../../configs/keys";
import { useSelector } from "react-redux";
import useOAuth from "../../hooks/useOAuth";

const _validateForm = (email, password) => {
  const errors = [];
  if (!email) {
    errors.push("Email không được để trống");
  }
  if (!password) {
    errors.push("Mật khẩu không được để trống");
  }
  return errors;
};

const LoginPage = props => {
  const Router = useRouter();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const user = useSelector(({ user }) => user.user);
  const { openOAuthPopup } = useOAuth(Router.query.ref || "/");

  useEffect(() => {
    if (user.id) {
      // already login
      Router.push("/");
    }
    window.scrollTo(0, 0);
  }, [user.id]);

  const handleEmailChange = useCallback(event => {
    event.persist();
    setEmail(event.target.value);
    setErrors(errors => ({ ...errors, email: undefined }));
  }, []);

  const handlePasswordChange = useCallback(event => {
    event.persist();
    setPassword(event.target.value);
    setErrors(errors => ({ ...errors, password: undefined }));
  }, []);

  const handleSubmit = useCallback(() => {
    if (_validateForm(email, password).length) {
      setErrors(_validateForm(email, password));
      return;
    }
    setLoading(true);
    const src = Router.query.ref || "/";
    Login(email, password, src)
      .then(data => {
        setLoading(false);
      })
      .catch(error => {
        if (error.response.status === 401) {
          setErrors(["Sai thông tin đăng nhập"]);
        }
        setLoading(false);
      });
  }, [email, password]);

  return (
    <AppLayout title="Đăng nhập" breadcrumb={{ title: "" }} disablePadding>
      <div className="login-page bg-white min-h-screen">
        {Router.query.msg ? (
          <div className="text-red-500 text-center mb-5 text-base px-5">
            {Router.query.msg}
          </div>
        ) : null}
        <h1 className="login-page__title">Đăng nhập</h1>
        <p className="login-page__slogan">Mua sỉ đơn giản & an toàn</p>
        {errors.length > 0 && (
          <div className="login-page__errors">
            {errors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        )}
        <form className="mt-5 login-page__form">
          <FormControl>
            <InputLabel htmlFor="input-with-icon-adornment">
              SĐT hoặc Email
            </InputLabel>
            <Input
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <AccountCircle color="disabled" />
                </InputAdornment>
              }
              value={email}
              onChange={handleEmailChange}
              placeholder="SĐT hoặc Email"
            />
          </FormControl>
          <div className="mt-4"></div>
          <FormControl>
            <InputLabel htmlFor="input-with-icon-adornment">
              Mật khẩu
            </InputLabel>
            <Input
              id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <LockIcon color="disabled" />
                </InputAdornment>
              }
              value={password}
              onChange={handlePasswordChange}
              placeholder="******"
              type="password"
            />
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="text-white w-full"
            disabled={isLoading}
            onClick={handleSubmit}
            disableElevation
            style={{ marginTop: 20 }}
          >
            Đăng nhập
          </Button>
        </form>
        <div className="block px-3 my-3 text-right">
          <a href="/user/password/mail" className="text-underline">
            <u>Quên mật khẩu?</u>
          </a>
        </div>

        <hr className="mb-3"></hr>
        <div className="text-center text-gray-500">hoặc đăng nhập với</div>
        <div className="flex justify-center py-4">
          <img
            src="https://files.thitruongsi.com/assets/social-icons/zalo.png"
            className="w-[42px] h-[42px] rounded-full mr-7 border cursor-pointer"
            onClick={() => openOAuthPopup("zalo")}
          />
          <img
            src="https://files.thitruongsi.com/assets/social-icons/google.png"
            className="w-10 h-10 rounded-full mr-7 cursor-pointer"
            onClick={() => openOAuthPopup("google")}
          />
          <img
            src="https://files.thitruongsi.com/assets/social-icons/apple.png"
            className="w-10 h-10 rounded-full cursor-pointer"
            onClick={() => openOAuthPopup("apple")}
          />
        </div>
        <hr className="mb-3"></hr>

        <div className="flex flex-col items-center px-3">
          <span className="text-center text-gray-500 block">
            Bạn chưa có tài khoản?
          </span>
          <span className="my-2">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                if (Router.query.ref) {
                  window.sessionStorage.setItem(
                    REGISTER_SUCCESS_CALLBACK,
                    Router.query.ref
                  );
                }
                Router.push(`/user/register?ref=${Router.query.ref || " / "}`);
              }}
              disableElevation
            >
              Đăng ký ngay
            </Button>
          </span>
          <span className="block text-center text-gray-500">
            Kết nối với hơn 60,000 nhà cung cấp & nhận nhiều đặc quyền khác.
            <div className="mt-10 justify-center">
              Bằng việc tiếp tục, bạn đã đồng ý với{" "}
              <a
                className="text-blue-500"
                target="_blank"
                href="https://thitruongsi.com/pages/dieu-khoan-dich-vu.html"
              >
                Điều khoản & điều kiện
              </a>{" "}
              cùng{" "}
              <a
                className="text-blue-500"
                href="https://thitruongsi.com/pages/chinh-sach-bao-mat-thong-tin.html"
                target="_blank"
              >
                chính sách bảo mật và chia sẻ thông tin
              </a>{" "}
              của Thị Trường Sỉ.
            </div>
          </span>
        </div>
      </div>
    </AppLayout>
  );
};

export default LoginPage;
